// footer mapping configlet widget styles
.editable-footer-mapping {
  .footer-add-row {
    display: flex;
    padding-bottom: 1em;

    label {
      min-width: 6em;
    }
  }

  .footer-mappings {
    padding: 0.5em;
    border-style: solid;

    .mapping-group {
      padding: 0.5em;
      border-style: solid;
    }

    button {
      margin-top: 0.5em;
      margin-right: 0.5em;
    }
  }
}

.lightsite-editable-footer .blocks-group-wrapper {
  padding-top: 1em;
  padding-bottom: 1em;

  & > div {
    margin-bottom: 2em;
  }

  & > p {
    margin-bottom: 1em;
  }
}

.lightsite-editable-footer,
.contenttype-footer #view,
.contenttype-footer #page-edit {
  text-align: center;

  .block.gridBlock {
    display: flex;
    justify-content: center;

    .block.image {
      display: flex;
      justify-content: center;

      figure:after {
        content: none !important;
      }

      a {
        display: flex;
        justify-content: flex-end;
      }

      figure {
        align-items: center;
      }

      img {
        width: initial !important;
        max-height: 128px !important;
        aspect-ratio: unset !important;
      }
    }

    .slate:not(.inner) {
      background-color: unset !important;
    }
  }
}
