.block.highlight {
  &.has--descriptionColor--lightsite-main-color-1,
  &.has--descriptionColor--highlight-custom-color-1 {
    .highlight-description {
      background: $lightsite-main-color-1;
      color: $lightsite-main-color-text-1;

      h2,
      a,
      p {
        color: $lightsite-main-color-text-1;
      }
    }
  }

  &.has--descriptionColor--lightsite-main-color-2,
  &.has--descriptionColor--highlight-custom-color-2 {
    .highlight-description {
      background: $lightsite-main-color-2;
      color: $lightsite-main-color-text-2;

      h2,
      a,
      p {
        color: $lightsite-main-color-text-2;
      }
    }
  }

  &.has--descriptionColor--lightsite-main-color-3,
  &.has--descriptionColor--highlight-custom-color-3 {
    .highlight-description {
      background: $lightsite-main-color-3;
      color: $lightsite-main-color-text-3;

      h2,
      a,
      p {
        color: $lightsite-main-color-text-3;
      }
    }
  }
}

#page-edit,
#page-add {
  .block-editor-highlight {
    &.has--descriptionColor--lightsite-main-color-1,
    &.has--descriptionColor--highlight-custom-color-1 {
      .highlight-description {
        background: $lightsite-main-color-1;
        color: $lightsite-main-color-text-1;

        h2,
        a,
        p {
          color: $lightsite-main-color-text-1;
        }
      }
    }

    &.has--descriptionColor--lightsite-main-color-2,
    &.has--descriptionColor--highlight-custom-color-2 {
      .highlight-description {
        background: $lightsite-main-color-2;
        color: $lightsite-main-color-text-2;

        h2,
        a,
        p {
          color: $lightsite-main-color-text-2;
        }
      }
    }

    &.has--descriptionColor--lightsite-main-color-3,
    &.has--descriptionColor--highlight-custom-color-3 {
      .highlight-description {
        background: $lightsite-main-color-3;
        color: $lightsite-main-color-text-3;

        h2,
        a,
        p {
          color: $lightsite-main-color-text-3;
        }
      }
    }
  }
}

.theme-picker-widget {
  .button.lightsite-main-color-1,
  .button.lightsite-main-color-1.active,
  .button.lightsite-main-color-1:hover,
  .button.active.lightsite-main-color-1:hover {
    background: $lightsite-main-color-1;
  }

  .button.lightsite-main-color-2,
  .button.lightsite-main-color-2.active,
  .button.lightsite-main-color-2:hover,
  .button.active.lightsite-main-color-2:hover {
    background: $lightsite-main-color-2;
  }

  .button.lightsite-main-color-3,
  .button.lightsite-main-color-3.active,
  .button.lightsite-main-color-3:hover,
  .button.active.lightsite-main-color-3:hover {
    background: $lightsite-main-color-3;
  }
}
