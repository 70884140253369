@font-face {
  font-family: 'Aleo';
  src: url('./fonts/Aleo/Aleo-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900; /* Black */
  src: url('./fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900; /* Black */
  src: url('./fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300; /* Light */
  src: url('./fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300; /* Light */
  src: url('./fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100; /* Thin */
  src: url('./fonts/Lato/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100; /* Thin */
  src: url('./fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
}
