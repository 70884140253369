body.jyu-light-theme-enabled {
  $lightgrey: #fbfbfb;
  $blue: #002957;
  $blue75: #405f81;
  $terracotta: #f1563f;
  $gold: #c29a5b;
  $gold90: #c9a56c;
  $gold75: #d1b384;
  $gold-contrast: #926e36;

  $main-font: Aleo;
  $secondary-font: Lato;

  $p1: 15px;
  $p2: 30px;
  $p3: 45px;
  $p4: 60px;

  .language-selector {
    a {
      color: $blue;
      font-family: $secondary-font;

      &:hover {
        text-decoration: underline;
        text-underline-position: under;

        &::after {
          text-decoration: underline;
          text-underline-position: under;
        }
      }

      &::after:hover {
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }

  .navigation ul.desktop-menu .submenu-wrapper {
    margin-top: 18px;
  }

  .desktop-menu li button {
    font-family: $secondary-font;
  }

  .submenu-wrapper {
    .current.active {
      .left-arrow {
        color: $gold;
      }
    }

    .subitem-wrapper a span {
      color: $blue;
      font-family: $main-font;
    }

    .subsubitem-wrapper a span {
      color: black;
      font-family: $secondary-font;
    }

    a.submenu-header {
      h2 {
        color: $blue;
        font-family: $main-font;
        font-weight: bold;
      }

      &:hover {
        h2 {
          text-decoration: underline;
          text-underline-position: under;
        }
      }
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 100px;
      background-color: $lightgrey;

      &:hover {
        background-color: $gold75;
      }
    }
  }

  .navigation .item.active::before,
  .navigation .item:hover::before {
    bottom: -28px;
    border-color: $blue;
  }

  .navigation ul.desktop-menu .submenu {
    background-color: $lightgrey;
  }

  .breadcrumbs .breadcrumb a.home {
    color: $gold90 !important;
  }

  .breadcrumbs .breadcrumb a {
    color: $gold-contrast !important;
    text-underline-position: under;

    &:hover {
      text-decoration: none;
    }
  }

  .header-wrapper .header,
  .header-container {
    max-width: none !important;
    margin: 0 !important;
  }

  .header .logo-nav-wrapper {
    padding-bottom: 0 !important;
  }

  .breadcrumbs {
    background-color: $lightgrey !important;
  }

  #page-document {
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: $main-font;
    }

    p {
      font-family: $secondary-font;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: row;

    span {
      display: flex;
      align-items: center;
      padding-left: $p1;
      color: $blue;
      font-family: $main-font;
      font-weight: bold;
    }
  }

  .jyu-theme-footer-container {
    display: grid;
    background-color: $lightgrey;
    grid-template-columns: 1fr 1fr 1fr;

    .social-icons {
      display: flex;
      justify-content: center;
      gap: 25px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .footer-end-row {
      display: flex;
      align-items: center;
      justify-content: end;

      a {
        margin-right: $p1;
        margin-left: $p1;
        color: $blue;
        font-family: $secondary-font;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media only screen and (max-width: $largest-mobile-screen) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;

      .footer-end-row {
        justify-content: center;
        margin: 1rem auto;
      }

      .social-icons {
        justify-content: center;
        margin: 1rem auto;
      }
    }
  }
}
