// Some specific adjustments to different block colors based on lightsite colors
@import '_blocks';

// Replicate logic from highlight-block
@import '_highlight';

// Own styles
@import '_footer';
@import '_header';
@import '_temp';

// Add a bit coloring to edit view background color widgets
.theme-picker-widget button.lightsite-main-color-1 {
  background-color: $lightsite-main-color-1;
}

.theme-picker-widget button.lightsite-main-color-2 {
  background-color: $lightsite-main-color-2;
}

.theme-picker-widget button.lightsite-main-color-3 {
  background-color: $lightsite-main-color-3;
}

// In general, apply customized background and text color to over customized background colors
.has--background-color--lightsite-main-color-1,
.blocks-group-wrapper.lightsite-main-color-1 {
  background-color: $lightsite-main-color-1;
  color: $lightsite-main-color-text-1;
  & > p,
  & > span,
  & > li,
  & > ul,
  & > ol,
  & > strong,
  & > em,
  & > figcaption,
  & > a:not(.item),
  & > blockquote,
  & > h2,
  & > h3,
  & > h1 {
    color: $lightsite-main-color-text-1;
  }
  .block {
    background-color: $lightsite-main-color-1;
  }
}
.has--background-color--lightsite-main-color-2,
.blocks-group-wrapper.lightsite-main-color-2 {
  background-color: $lightsite-main-color-2;
  color: $lightsite-main-color-text-2;
  & > p,
  & > span,
  & > li,
  & > ul,
  & > ol,
  & > strong,
  & > em,
  & > figcaption,
  & > a:not(.item),
  & > blockquote,
  & > h2,
  & > h3,
  & > h1 {
    color: $lightsite-main-color-text-2;
  }
  .block {
    background-color: $lightsite-main-color-2;
  }
}

.has--background-color--lightsite-main-color-3,
.blocks-group-wrapper.lightsite-main-color-3 {
  background-color: $lightsite-main-color-3;
  color: $lightsite-main-color-text-3;
  & > p,
  & > span,
  & > li,
  & > ul,
  & > ol,
  & > strong,
  & > em,
  & > figcaption,
  & > a:not(.item),
  & > blockquote,
  & > h2,
  & > h3,
  & > h1 {
    color: $lightsite-main-color-text-3;
  }
  .block {
    background-color: $lightsite-main-color-3;
  }
}

// Set footer top border to main color 1
.lightsite-editable-footer {
  border-top-width: 8px;
  border-top-style: solid;
  border-top-color: $lightsite-main-color-1;
}

// Set navigation underlines to main color 1
.jyu-light-theme-not-enabled {
  .navigation .item.active::before,
  .navigation .item:hover::before {
    border-bottom-color: $lightsite-main-color-1;
  }
}

// Set breadcrumbs color to main color 2
.jyu-light-theme-not-enabled {
  #main .breadcrumbs {
    background-color: $lightsite-main-color-2;

    .breadcrumb {
      .home {
        color: $lightsite-main-color-text-2 !important;
      }

      .divider {
        color: $lightsite-main-color-text-2;
      }

      .divider:before {
        color: unset !important;
      }

      .section {
        color: $lightsite-main-color-text-2 !important;
      }
    }
  }
}

// Set megamenu background main color 3
.jyu-light-theme-not-enabled {
  .navigation ul.desktop-menu .submenu {
    background: $lightsite-main-color-3;
    box-shadow: unset !important;
    color: $lightsite-main-color-text-3;

    h2,
    span {
      color: $lightsite-main-color-text-3;
    }

    li.subitem-wrapper {
      border-left-color: $lightsite-main-color-text-3;
    }

    button.close {
      background: $lightsite-main-color-3;
      color: $lightsite-main-color-text-3;
    }
  }
}

// Set search button background color to main color 1
.jyu-light-theme-not-enabled {
  .header-wrapper .header .search-wrapper .search button:hover {
    background: $lightsite-main-color-1;
    color: $lightsite-main-color-text-1;
  }
}

// volto-light-theme tries to set max height, we don't want that
.logo {
  max-height: unset !important;
}
// volto-light-theme tries to color the house icon, we want our own
body #main > .breadcrumbs a.home svg {
  fill: currentColor !important;
}
// volto-light-theme tries to override this link color, we don't want that
.block.slider .ui.button {
  color: inherit !important;
}
