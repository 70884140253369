// Fix underline locations in (non-mega)menu items
.navigation .item.active::before,
.navigation .item:hover::before {
  bottom: -1em;
}

.header-wrapper .header .tools-wrapper .tools .anontools:after {
  content: none;
}

.header-wrapper .header .tools-wrapper .tools .anontools a {
  font-size: 1em;
}

.header-wrapper .header .tools-wrapper .language-selector a {
  text-transform: none;
}

// Fix megamenu scrolling issues for big megamenus
body {
  .navigation .submenu-wrapper {
    width: calc(100vw - 20px) !important;
    max-height: 75vh;
    overflow-y: auto;
  }

  // adjust for toolbar
  &:has(.toolbar) {
    .navigation .submenu-wrapper {
      width: calc(100vw - 50px) !important;
    }
  }
}

// Keep the original column-reverse order of logo-nav and tools
.header-wrapper .header {
  flex-direction: column-reverse;
}

// Hide anontools by default (RIKSA)
.anontools {
  display: none !important;
}

// Removing main navigation padding (RIKSA)
.header-wrapper .header .logo-nav-wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
}

// fiksataan megamenun alla olevan kuvan z-index niin ettei se tule menun päälle (RIKSA)
.content-area .a.container {
  z-index: 1;
}
