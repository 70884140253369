body,
body #page-edit,
body #page-add {
  // Button (Fix button borders, text color and background)
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .button-container .ui.button {
      border-color: $lightsite-main-color-text-1 !important;
      background-color: $lightsite-main-color-1 !important;
      color: $lightsite-main-color-text-1 !important;

      &:hover,
      &:active,
      &:focus {
        border-color: $lightsite-main-color-1 !important;
        background-color: $lightsite-main-color-text-1 !important;
        color: $lightsite-main-color-1 !important;
      }
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .button.container .ui.button {
      border-color: $lightsite-main-color-text-2 !important;
      background-color: $lightsite-main-color-2 !important;
      color: $lightsite-main-color-text-2 !important;

      &:hover,
      &:active,
      &:focus {
        border-color: $lightsite-main-color-2 !important;
        background-color: $lightsite-main-color-text-2 !important;
        color: $lightsite-main-color-2 !important;
      }
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .button.container .ui.button {
      border-color: $lightsite-main-color-text-3 !important;
      background-color: $lightsite-main-color-3 !important;
      color: $lightsite-main-color-text-3 !important;

      &:hover,
      &:active,
      &:focus {
        border-color: $lightsite-main-color-3 !important;
        background-color: $lightsite-main-color-text-3 !important;
        color: $lightsite-main-color-3 !important;
      }
    }
  }
  // Teaser (Fix headline and summary)
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .block.teaser .content p,
    .block.teaser .content h2 {
      color: $lightsite-main-color-text-1;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .block.teaser .content p,
    .block.teaser .content h2 {
      color: $lightsite-main-color-text-2;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .block.teaser .content p,
    .block.teaser .content h2 {
      color: $lightsite-main-color-text-3;
    }
  }
  // Separator (fix the line)
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .block.separator .line:not(.inner)::after {
      border-color: $lightsite-main-color-text-1;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .block.separator .line:not(.inner)::after {
      border-color: $lightsite-main-color-text-2;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .block.separator .line:not(.inner)::after {
      border-color: $lightsite-main-color-text-3;
    }
  }
  // Fix listing (underlines and texts)
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .block.listing .listing-item {
      border-bottom-color: $lightsite-main-color-text-1 !important;
    }

    .block.listing .listing-item h2,
    .block.listing .listing-item p {
      color: $lightsite-main-color-text-1 !important;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .block.listing .listing-item {
      border-bottom-color: $lightsite-main-color-text-2 !important;
    }

    .block.listing .listing-item h2,
    .block.listing .listing-item p {
      color: $lightsite-main-color-text-2 !important;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .block.listing .listing-item {
      border-bottom-color: $lightsite-main-color-text-3 !important;
    }

    .block.listing .listing-item h2,
    .block.listing .listing-item p {
      color: $lightsite-main-color-text-3 !important;
    }
  }
  // Accordion (many little details)
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .accordion-block .ui.styled.accordion .title.accordion-title,
    .accordion-block .ui.styled.accordion .rah-static,
    .accordion-block .ui.styled.accordion .rah-animating,
    .accordion-block .blocks-group-wrapper {
      background-color: $lightsite-main-color-1 !important;
    }

    .accordion-block .ui.styled.accordion span,
    .accordion-block .ui.styled.accordion input,
    .block .text-slate-editor-inner input,
    .accordion-block .ui.styled.accordion p {
      color: $lightsite-main-color-text-1 !important;
    }

    .accordion-block .ui.styled.accordion {
      border-color: $lightsite-main-color-text-1 !important;
    }

    .accordion-block .ui.styled.accordion .title.accordion-title > svg {
      fill: $lightsite-main-color-text-1 !important;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .accordion-block .ui.styled.accordion .title.accordion-title,
    .accordion-block .ui.styled.accordion .rah-static,
    .accordion-block .ui.styled.accordion .rah-animating,
    .accordion-block .blocks-group-wrapper {
      background-color: $lightsite-main-color-2 !important;
    }

    .accordion-block .ui.styled.accordion span,
    .accordion-block .ui.styled.accordion input,
    .block .text-slate-editor-inner input,
    .accordion-block .ui.styled.accordion p {
      color: $lightsite-main-color-text-2 !important;
    }

    .accordion-block .ui.styled.accordion {
      border-color: $lightsite-main-color-text-2 !important;
    }

    .accordion-block .ui.styled.accordion .title.accordion-title > svg {
      fill: $lightsite-main-color-text-2 !important;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .accordion-block .ui.styled.accordion .title.accordion-title,
    .accordion-block .ui.styled.accordion .rah-static,
    .accordion-block .ui.styled.accordion .rah-animating,
    .accordion-block .blocks-group-wrapper {
      background-color: $lightsite-main-color-3 !important;
    }

    .accordion-block .ui.styled.accordion span,
    .accordion-block .ui.styled.accordion input,
    .block .text-slate-editor-inner input,
    .accordion-block .ui.styled.accordion p {
      color: $lightsite-main-color-text-3 !important;
    }

    .accordion-block .ui.styled.accordion {
      border-color: $lightsite-main-color-text-3 !important;
    }

    .accordion-block .ui.styled.accordion .title.accordion-title > svg {
      fill: $lightsite-main-color-text-3 !important;
    }
  }
  // Slate
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .block.slate .text-slate-editor-inner span {
      color: $lightsite-main-color-text-1 !important;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .block.slate .text-slate-editor-inner span {
      color: $lightsite-main-color-text-2 !important;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .block.slate .text-slate-editor-inner span {
      color: $lightsite-main-color-text-3 !important;
    }
  }

  // Introduction (easy)
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .block.introduction .block-container p,
    .block.introduction .text-slate-editor-inner span {
      color: $lightsite-main-color-text-1 !important;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .block.introduction .block-container p,
    .block.introduction .text-slate-editor-inner span {
      color: $lightsite-main-color-text-2 !important;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .block.introduction .block-container p,
    .block.introduction .text-slate-editor-inner span {
      color: $lightsite-main-color-text-3 !important;
    }
  }
  // Heading
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .block.heading .heading-wrapper h2 {
      color: $lightsite-main-color-text-1 !important;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .block.heading .heading-wrapper h2 {
      color: $lightsite-main-color-text-2 !important;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .block.heading .heading-wrapper h2 {
      color: $lightsite-main-color-text-3 !important;
    }
  }
  // Search
  .has--background-color--lightsite-main-color-1,
  .blocks-group-wrapper.lightsite-main-color-1 {
    .block.search h2,
    .block.search p,
    .block.search span {
      color: $lightsite-main-color-text-1 !important;
    }

    .listing-item {
      border-bottom-color: $lightsite-main-color-text-1 !important;
    }
  }

  .has--background-color--lightsite-main-color-2,
  .blocks-group-wrapper.lightsite-main-color-2 {
    .block.search h2,
    .block.search p,
    .block.search span {
      color: $lightsite-main-color-text-2 !important;
    }

    .listing-item {
      border-bottom-color: $lightsite-main-color-text-2 !important;
    }
  }

  .has--background-color--lightsite-main-color-3,
  .blocks-group-wrapper.lightsite-main-color-3 {
    .block.search h2,
    .block.search p,
    .block.search span {
      color: $lightsite-main-color-text-3 !important;
    }

    .listing-item {
      border-bottom-color: $lightsite-main-color-text-3 !important;
    }
  }
}
